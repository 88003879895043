@import '../../styles/base/variables';
@import '../../styles/base/mixins';

.ocbWrapper {
  align-self: center;
  width: 100%;
  margin-top: 0.875rem;
  text-align: center;

  :global(.isTouch) & {
    margin-left: 0;
  }

  @include screen-md {
    margin-left: 1.25rem;
    margin-top: 0;
  }

  .dcButton {
    width: 100%;
    margin-top: 1.25rem;

    span {
      margin-top: 1rem;
    }
  }

  .catalogButton {
    width: 100%;
    border: none;
    font-size: 0.875rem;
    line-height: 1;
    padding-top: 1.0625rem;
    padding-bottom: 1.0625rem;

    @include screen-md {
      display: flex;
      align-items: center;
      height: 100%;
      padding: 0.8125rem 1.75rem;
      font-size: 0.75rem;
    }

    span {
      color: $gb_white;
    }
  }
}
